import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const PostsTableModel = (
  handleShowClick: any,
  handleShowProfileImage: any,
  handleShowPostImage: any,
  setModalOpen: any,
  setDeletingId: any
) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Username",
    dataIndex: "user",
    key: "user",
    render: (value: any) => {
      return <p>{value?.username}</p>;
    },
  },
  {
    title: "User FullName",
    dataIndex: "user",
    key: "user",
    render: (value: any) => {
      return <p>{value?.name}</p>;
    },
  },
  {
    title: "Profile Picture",
    dataIndex: "user",
    key: "user",
    render: (value: any) => {
      return (
        <button onClick={() => handleShowProfileImage(value?.profile_picture)}>
          <img src={value?.profile_picture} alt="" className="w-24 h-24" />
        </button>
      );
    },
  },
  {
    title: "Caption",
    dataIndex: "caption",
    key: "caption",
    render: (value: any) => {
      return (
        <p>{value?.trim() === "" || value === null ? "No Caption" : value}</p>
      );
    },
  },
  {
    title: "Picture",
    dataIndex: "picture",
    key: "picture",
    render: (value: any) => {
      return (
        <button onClick={() => handleShowPostImage(value)}>
          <img src={value} alt="" className="w-24 h-24" />
        </button>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>Show Post</p>}>
            <button
              className="bg-[#295e56] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                handleShowClick(value);
              }}
            >
              <EyeOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Delete Post</p>}>
            <button
              className="bg-[red] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                setModalOpen(true);
                setDeletingId(value);
              }}
            >
              <DeleteOutlined className="text-white" />
            </button>
          </Popover>
        </div>
      );
    },
  },
];

export default PostsTableModel;
