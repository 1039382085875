import { DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const VendorsTableModel = (
  handleView: any,
  handleEdit: any,
  setModalOpen: any,
  setDeletingId: any,
  handleCreateAdmin: any
) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Phone",
    dataIndex: "phone_number",
    key: "phone_number",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Base Fee",
    dataIndex: "base_fee",
    key: "base_fee",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Rating",
    dataIndex: "rating",
    key: "rating",
    render: (value: any) => {
      return <p>{value} / 5</p>;
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (value: any) => {
      return <p className="line-clamp-1 text-ellipsis">{value}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>View</p>}>
            <button
              className="bg-[#295e56] p-2 flex items-center justify-center rounded-md"
              onClick={() => handleView(value)}
            >
              <EyeOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Edit</p>}>
            <button
              className="bg-[#00cfe8] p-2 flex items-center justify-center rounded-md"
              onClick={() => handleEdit(value)}
            >
              <EditOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Delete</p>}>
            <button
              className="bg-[red] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                setModalOpen(true);
                setDeletingId(value);
              }}
            >
              <DeleteOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Create Admin</p>}>
            <button
              className="bg-green-400 p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                handleCreateAdmin(value);
              }}
            >
              <PlusCircleOutlined className="text-white" />
            </button>
          </Popover>
        </div>
      );
    },
  },
];

export default VendorsTableModel;
