import { useFormik } from "formik"
import * as yup from "yup";
import LoginModel from "model/LoginModel";
import { DEFAULT_ERROR_MESSAGE } from "constants/index";
import { useDispatch } from "react-redux";
import { loginAction } from "redux/actions/loginAction";

const useLoginForm = () => {
    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: yup.object({
            username: yup.string().required(DEFAULT_ERROR_MESSAGE),
            password: yup.string().required(DEFAULT_ERROR_MESSAGE)
        }),
        onSubmit: async (values: LoginModel) => {
            await dispatch(loginAction({
                username: values.username,
                password: values.password
            }));
        }
    });

    return formik;
}

export { 
    useLoginForm
}