import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { listItemsAction } from "redux/actions/listItemsAction";
import { listReportsEndpoint, showReportEndpoint } from "api/apiUrls";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import ReportsTableModel from "model/tables/ReportsTableModel";
import { listSingleItemAction } from "redux/actions/listSingleItemAction";
import { SHOW_REPORT_PATH } from "Routes/paths";

const ReportsIndex = () => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { listItemsLoading, items, pagination } = useSelector(
    (state: RootState) => state.listItemsReducer
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState("");

  useEffect(() => {
    const page = searchParams.get("page") ?? 1;
    dispatch(listItemsAction(listReportsEndpoint, `page=${page}`));
  }, [searchParams]);

  return (
    <section className="w-full h-full overflow-y-scroll">
      <div className="w-full px-10 pt-10 pb-32 flex flex-col items-end">
        <Table
          className="m-4 shadow-md rounded-md w-[97%] border border-gray-300 overflow-x-auto"
          dataSource={items}
          loading={listItemsLoading}
          tableLayout="auto"
          columns={ReportsTableModel(
            async (id: string) => {
              await dispatch(
                listSingleItemAction(showReportEndpoint, id, () => {
                  navigate(SHOW_REPORT_PATH);
                })
              );
            },
            setDeleteModalOpen,
            setDeletingId
          )}
          pagination={{
            current:
              Number(searchParams.get("page")) === 0
                ? 1
                : Number(searchParams.get("page")),
            pageSize: 8,
            total: Number(pagination?.last_page) * 8,
            showSizeChanger: false,
            onChange: (page: number) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            },
          }}
        />
      </div>
    </section>
  );
};

export default ReportsIndex;
