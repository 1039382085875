import React, { useState } from "react";
import { LogoutIcon, UserIcon } from "assets/icons";
import { Popover } from "antd";
import { useDispatch } from "react-redux";
import { LOG_OUT } from "redux/types";

const Navbar = () => {
  const dispatch: any = useDispatch();

  const [open, setOpen] = useState(false);

  return (
    <div className="w-full h-full flex items-center justify-end px-4">
      <Popover
        content={
          <div className="w-full h-full flex flex-col items-start">
            <button
              onClick={() => dispatch({ type: LOG_OUT })}
              className="font-semibold flex items-center gap-x-3"
            >
              <img src={LogoutIcon} alt="logout" width={18} height={18} />
              <p>Logout</p>
            </button>
          </div>
        }
        trigger="click"
        className="hover:cursor-pointer"
        open={open}
        onOpenChange={(visible: boolean) => setOpen(visible)}
      >
        <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
          <img src={UserIcon} alt="user icon" width={28} height={28} />
        </div>
      </Popover>
    </div>
  );
};

export default Navbar;
