import { useFormik } from "formik"
import * as yup from "yup";
import CreateHowToPostModel from "model/CreateHowToPostModel";
import { DEFAULT_ERROR_MESSAGE } from "constants/index";
import { Step, Tip } from "screens/HowToPosts/CreateHowToPost";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { createItemAction } from "redux/actions/createItemAction";
import { createHowToPostEndpoint } from "api/apiUrls";
import { useNavigate } from "react-router";

const useCreateHowToPostForm = (steps: Step[], tips: Tip[]) => {
    const navigate = useNavigate();

    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            difficulty_level: ''
        },
        validationSchema: yup.object({
            title: yup.string().required(DEFAULT_ERROR_MESSAGE),
            description: yup.string().required(DEFAULT_ERROR_MESSAGE),
            difficulty_level: yup.string().required(DEFAULT_ERROR_MESSAGE)
        }),
        onSubmit: async (values: CreateHowToPostModel) => {
            if (steps.length < 1) {
                notification.error({
                    message: 'Enter at least 1 step'
                });
                return;
            }
            if (tips.length < 1) {
                notification.error({
                    message: 'Enter at least 1 tip'
                });
                return;
            }
            const formData: any = {
                title: values.title,
                description: values.description,
                difficulty_level: values.difficulty_level,
                steps: steps.map((item: Step) => {
                    return {
                        text: item.text,
                        picture: item.picture
                    }
                }),
                tips: tips.map((item: Tip) => {
                    return {
                        text: item.text
                    }
                })
            }
            await dispatch(createItemAction(createHowToPostEndpoint, formData, () => {
                navigate(-1);
            }));
        }
    });

    return formik;
}

export {
    useCreateHowToPostForm
}