import {
  CREATE_HOW_TO_POST,
  CREATE_VENDOR_ADMIN_PATH,
  CREATE_VENDOR_PATH,
  HOME_PATH,
  HOW_TO_POSTS,
  LOGIN_PATH,
  POSTS_PATH,
  REPORTS_PATH,
  SHOW_REPORT_PATH,
  SHOW_VENDOR_ADMIN_PATH,
  SHOW_VENDOR_PATH,
  SHOW_POST_PATH,
  UPDATE_VENDOR_ADMIN_PATH,
  UPDATE_VENDOR_PATH,
  USERS_PATH,
  VENDOR_ADMINS_PATH,
  VENDORS_PATH,
} from "Routes/paths";
import Login from "screens/Login/Login";
import DashboardParent from "screens/DashboardParent";
import Home from "screens/Home/Home";
import Index from "screens/Users/Index";
import HowToPostsIndex from "screens/HowToPosts/HowToPostsIndex";
import CreateHowToPost from "screens/HowToPosts/CreateHowToPost";
import VendorsIndex from "screens/Vendors/VendorsIndex";
import CreateVendor from "screens/Vendors/CreateVendor";
import UpdateVendor from "screens/Vendors/UpdateVendor";
import ShowVendor from "screens/Vendors/ShowVendor";
import VendorAdminsIndex from "screens/VendorAdmins/VendorAdminsIndex";
import CreateVendorAdmin from "screens/VendorAdmins/CreateVendorAdmin";
import UpdateVendorAdmin from "screens/VendorAdmins/UpdateVendorAdmin";
import ShowVendorAdmin from "screens/VendorAdmins/ShowVendorAdmin";
import ReportsIndex from "screens/Reports/ReportsIndex";
import ShowReport from "screens/Reports/ShowReport";
import PostsIndex from "screens/Posts/PostsIndex";
import ShowPost from "screens/Posts/ShowPost";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: HOME_PATH,
    element: <Home />,
    parent: <DashboardParent />,
  },
  {
    path: USERS_PATH,
    element: <Index />,
    parent: <DashboardParent />,
  },
  {
    path: HOW_TO_POSTS,
    element: <HowToPostsIndex />,
    parent: <DashboardParent />,
  },
  {
    path: CREATE_HOW_TO_POST,
    element: <CreateHowToPost />,
    parent: <DashboardParent />,
  },
  {
    path: VENDORS_PATH,
    element: <VendorsIndex />,
    parent: <DashboardParent />,
  },
  {
    path: CREATE_VENDOR_PATH,
    element: <CreateVendor />,
    parent: <DashboardParent />,
  },
  {
    path: UPDATE_VENDOR_PATH,
    element: <UpdateVendor />,
    parent: <DashboardParent />,
  },
  {
    path: SHOW_VENDOR_PATH,
    element: <ShowVendor />,
    parent: <DashboardParent />,
  },
  {
    path: VENDOR_ADMINS_PATH,
    element: <VendorAdminsIndex />,
    parent: <DashboardParent />,
  },
  {
    path: CREATE_VENDOR_ADMIN_PATH,
    element: <CreateVendorAdmin />,
    parent: <DashboardParent />,
  },
  {
    path: UPDATE_VENDOR_ADMIN_PATH,
    element: <UpdateVendorAdmin />,
    parent: <DashboardParent />,
  },
  {
    path: SHOW_VENDOR_ADMIN_PATH,
    element: <ShowVendorAdmin />,
    parent: <DashboardParent />,
  },
  {
    path: REPORTS_PATH,
    element: <ReportsIndex />,
    parent: <DashboardParent />,
  },
  {
    path: SHOW_REPORT_PATH,
    element: <ShowReport />,
    parent: <DashboardParent />,
  },
  {
    path: POSTS_PATH,
    element: <PostsIndex />,
    parent: <DashboardParent />,
  },
  {
    path: SHOW_POST_PATH,
    element: <ShowPost />,
    parent: <DashboardParent />,
  },
];
