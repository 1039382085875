import React, { useEffect, useState } from "react";
import { LogoText } from "assets/icons";
import { SIDEBAR_ITEM_PROPS, SIDEBAR_ITEMS } from "constants/index";
import { useLocation, useNavigate } from "react-router";

const Sidebar = () => {
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case "/":
        setSelectedItem(SIDEBAR_ITEMS[0].label);
        return;
      case "/users":
        setSelectedItem(SIDEBAR_ITEMS[1].label);
        return;
      case "/how-to-posts":
        setSelectedItem(SIDEBAR_ITEMS[2].label);
        return;
      case "/vendors":
        setSelectedItem(SIDEBAR_ITEMS[3].label);
        return;
      case "/vendors/admins":
        setSelectedItem(SIDEBAR_ITEMS[4].label);
        return;
      case "/reports":
        setSelectedItem(SIDEBAR_ITEMS[5].label);
        return;
      case "/posts":
        setSelectedItem(SIDEBAR_ITEMS[6].label);
        return;
      default:
        setSelectedItem("");
        return;
    }
  }, [pathname]);

  return (
    <div className="w-full h-full flex flex-col items-center pt-6">
      <img src={LogoText} alt="logo" />
      <ul className="w-full flex flex-col gap-y-5 pt-10 px-8">
        {SIDEBAR_ITEMS.map((item: SIDEBAR_ITEM_PROPS, idx: number) => {
          return (
            <li
              onClick={() => {
                navigate(item.route);
              }}
              className={`${
                selectedItem === item.label
                  ? "bg-white text-black"
                  : "bg-transparent text-white"
              } flex items-center gap-x-3 w-full py-2 px-3 rounded-md duration-150 ${
                selectedItem === item.label ? "translate-x-4" : "translate-x-0"
              } hover:cursor-pointer`}
            >
              <img
                src={
                  selectedItem === item.label ? item.selectedIcon : item.icon
                }
                alt={item.label}
                width={24}
                height={24}
              />
              <p>{item.label}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
