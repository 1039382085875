import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { RootState } from "redux/rootReducer";
import { listItemsAction } from "redux/actions/listItemsAction";
import {
  deleteVendorAdminEndpoint,
  listVendorAdminsEndpoint,
  showVendorAdminEndpoint,
} from "api/apiUrls";
import { Modal, Select, Table } from "antd";
import VendorAdminTableModel from "model/tables/VendorAdminTableModel";
import { deleteItemAction } from "redux/actions/deleteItemAction";
import { listSingleItemAction } from "redux/actions/listSingleItemAction";
import { SHOW_VENDOR_ADMIN_PATH, UPDATE_VENDOR_ADMIN_PATH } from "Routes/paths";
import { SET_VENDOR_ID } from "redux/types";
import { CaretDownOutlined } from "@ant-design/icons";
import { VENDOR_STATUS_DROPDOWN } from "constants/index";

const VendorAdminsIndex = () => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { listItemsLoading, items, pagination } = useSelector(
    (state: RootState) => state.listItemsReducer
  );
  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteItemReducer
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState("");

  useEffect(() => {
    const page = searchParams.get("page") ?? 1;
    const deleted = searchParams.get("deleted") ?? "false";
    dispatch(
      listItemsAction(
        listVendorAdminsEndpoint,
        `deleted=${deleted}&page=${page}`
      )
    );
  }, [searchParams]);

  return (
    <section className="w-full h-full overflow-y-scroll">
      <div className="w-full px-10 pt-10 pb-32 flex flex-col items-start">
        <Modal
          title="Delete Confirmation"
          open={deleteModalOpen}
          onOk={async () => {
            await dispatch(
              deleteItemAction(deleteVendorAdminEndpoint, deletingId)
            );
            setDeleteModalOpen(false);
            const page = searchParams?.get("page") ?? 1;
            await dispatch(
              listItemsAction(listVendorAdminsEndpoint, `page=${page}`)
            );
          }}
          onCancel={() => setDeleteModalOpen(false)}
          okButtonProps={{
            className: "bg-[red] hover:!bg-[red] hover:opacity-50",
          }}
          className="w-full"
          confirmLoading={deleteLoading}
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
        <Select
          placeholder="Vendor Admin Status"
          className="min-h-[48px] w-1/2 ml-4"
          suffixIcon={<CaretDownOutlined className="text-xl" />}
          options={VENDOR_STATUS_DROPDOWN}
          onSelect={(value: any) => {
            if (value === "DELETED") {
              searchParams.set("deleted", "true");
              setSearchParams(searchParams);
            } else if (value === "ACTIVE") {
              searchParams.set("deleted", "false");
              setSearchParams(searchParams);
            }
            // formik.setFieldValue("difficulty_level", value);
          }}
        />
        <Table
          className="m-4 shadow-md rounded-md w-[97%] border border-gray-300 overflow-x-auto"
          dataSource={items}
          loading={listItemsLoading}
          tableLayout="auto"
          columns={VendorAdminTableModel(
            async (id: string) => {
              await dispatch(
                listSingleItemAction(
                  showVendorAdminEndpoint,
                  id,
                  (data: any) => {
                    dispatch({
                      type: SET_VENDOR_ID,
                      payload: data?.data?.vendor?.id,
                    });
                    navigate(SHOW_VENDOR_ADMIN_PATH);
                  }
                )
              );
            },
            async (id: string) => {
              await dispatch(
                listSingleItemAction(
                  showVendorAdminEndpoint,
                  id,
                  (data: any) => {
                    dispatch({
                      type: SET_VENDOR_ID,
                      payload: data?.data?.vendor?.id,
                    });
                    navigate(UPDATE_VENDOR_ADMIN_PATH);
                  }
                )
              );
            },
            setDeleteModalOpen,
            setDeletingId
          )}
          pagination={{
            current:
              Number(searchParams.get("page")) === 0
                ? 1
                : Number(searchParams.get("page")),
            pageSize: 8,
            total: Number(pagination?.last_page) * 8,
            showSizeChanger: false,
            onChange: (page: number) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            },
          }}
        />
      </div>
    </section>
  );
};

export default VendorAdminsIndex;
