//Auth Types
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";

// Login Types
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// List Items Types
export const LIST_ITEMS_LOADING = "LIST_ITEMS_LOADING";
export const LIST_ITEMS_SUCCESS = "LIST_ITEMS_SUCCESS";
export const LIST_ITEMS_FAILURE = "LIST_ITEMS_FAILURE";

// Create Item Types
export const CREATE_ITEM_LOADING = "CREATE_ITEM_LOADING";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_FAILURE = "CREATE_ITEM_FAILURE";

// Update Item Types
export const UPDATE_ITEM_LOADING = "UPDATE_ITEM_LOADING";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_FAILURE = "UPDATE_ITEM_FAILURE";

// Delete Item Types
export const DELETE_ITEM_LOADING = "DELETE_ITEM_LOADING";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";

// List Single Item Types
export const ITEM_LOADING = "ITEM_LOADING";
export const ITEM_SUCCESS = "ITEM_SUCCESS";
export const ITEM_FAILURE = "ITEM_FAILURE";

// Set Vendor Id
export const SET_VENDOR_ID = "SET_VENDOR_ID";

// Suspend User Types
export const SUSPEND_USER_LOADING = "SUSPEND_USER_LOADING";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const SUSPEND_USER_FAILURE = "SUSPEND_USER_FAILURE";