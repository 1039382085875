import { FC } from "react";
import LoaderComponent from "components/Loader/LoaderComponent";

type LoadingButtonProps = {
  loading: boolean;
  body: string;
  className?: string;
};

const LoadingButton: FC<LoadingButtonProps> = ({
  body,
  loading,
  className,
}) => {
  return (
    <button
      type="submit"
      className={`self-center mt-3 bg-main px-8 py-3 text-white font-semibold rounded-md max-h-[48px] flex items-center justify-center ${className}`}
    >
      {loading ? <LoaderComponent /> : body}
    </button>
  );
};

export default LoadingButton;
