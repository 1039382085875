export const LOGIN_PATH = "/login";

export const HOME_PATH = "/";

export const USERS_PATH = "/users";

export const HOW_TO_POSTS = "/how-to-posts";
export const CREATE_HOW_TO_POST = "/how-to-posts/create";

export const VENDORS_PATH = "/vendors";
export const CREATE_VENDOR_PATH = "/vendors/create";
export const UPDATE_VENDOR_PATH = "/vendors/update";
export const SHOW_VENDOR_PATH = "/vendors/show";

export const VENDOR_ADMINS_PATH = "/vendors/admins";
export const CREATE_VENDOR_ADMIN_PATH = "/vendors/admins/create";
export const UPDATE_VENDOR_ADMIN_PATH = "/vendors/admins/update";
export const SHOW_VENDOR_ADMIN_PATH = "/vendors/admins/show";

export const REPORTS_PATH = "/reports";
export const SHOW_REPORT_PATH = "/reports/show";

export const POSTS_PATH = "/posts";
export const SHOW_POST_PATH = "/post/show";