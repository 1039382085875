import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import InputField from "components/Shared/ui/InputField";

const ShowReport = () => {
  const { item } = useSelector(
    (state: RootState) => state.listSingleItemReducer
  );

  return (
    <section className="w-full h-full px-5 py-5">
      <div className="w-full bg-white max-h-[90%] min-h-[90%] rounded-md shadow-md border border-x-gray-300 px-4 py-4 flex flex-col items-start pb-10 overflow-y-auto relative">
        <h1 className="text-black text-2xl font-semibold">Show Report</h1>
        <div className="w-full h-1 bg-black mt-4" />
        <form className="w-full flex flex-col pt-6 gap-y-3">
          <h2 className="text-2xl">Reporter Data</h2>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Name</label>
              <InputField
                name="name"
                placeholder="Name"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reporter?.name}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Phone Number</label>
              <InputField
                name="phone_number"
                placeholder="Phone Number"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reporter?.phone_number}
                disabled
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Username</label>
              <InputField
                name="username"
                placeholder="Username"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reporter?.username}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Account Status</label>
              <InputField
                name="status"
                placeholder="Account Status"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reporter?.account_status}
                disabled
              />
            </div>
          </div>
          <div className="w-full h-1 bg-black mt-4" />
          <h2 className="text-2xl">Reported Data</h2>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Name</label>
              <InputField
                name="name"
                placeholder="Name"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reported?.name}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Phone Number</label>
              <InputField
                name="phone_number"
                placeholder="Phone Number"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reported?.phone_number}
                disabled
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Username</label>
              <InputField
                name="username"
                placeholder="Username"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reported?.username}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Account Status</label>
              <InputField
                name="status"
                placeholder="Account Status"
                type="text"
                errors={null}
                onChange={() => {}}
                value={item?.reported?.account_status}
                disabled
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ShowReport;
