import
{
    LIST_ITEMS_LOADING,
    LIST_ITEMS_SUCCESS,
    LIST_ITEMS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listItemsAction = (url: string, query?: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_ITEMS_LOADING, payload: true});
        const response = await listItemsService(`${url}?${query}`);
        dispatch({type: LIST_ITEMS_SUCCESS, payload: response.data});
        callback && callback();
    }   
    catch (err) {
        dispatch({type: LIST_ITEMS_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LIST_ITEMS_LOADING, payload: false});
    }
}