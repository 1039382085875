import {
  CaretDownOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { notification, Select } from "antd";
import InputField from "components/Shared/ui/InputField";
import { DIFFICULTY_LEVEL_OPTIONS } from "constants/index";
import { useState } from "react";
import { useCreateHowToPostForm } from "forms/createHowToPostForm";
import LoadingButton from "components/Shared/ui/LoadingButton";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

export type Step = {
  id: number;
  text?: string;
  picture?: any;
};

export type Tip = {
  id: number;
  text: string;
};

const CreateHowToPost = () => {
  const [stepsCount, setStepsCount] = useState(1);
  const [tipsCount, setTipsCount] = useState(1);

  const [currentStep, setCurrentStep] = useState<Step | null>(null);
  const [steps, setSteps] = useState<Step[]>([]);

  const [currentTip, setCurrentTip] = useState<Tip | null>(null);
  const [tips, setTips] = useState<Tip[]>([]);

  const formik = useCreateHowToPostForm(steps, tips);

  const { createLoading } = useSelector(
    (state: RootState) => state.createItemReducer
  );

  return (
    <section className="w-full h-full px-5 py-5">
      <div className="w-full bg-white max-h-[90%] min-h-[90%] rounded-md shadow-md border border-x-gray-300 px-4 py-4 flex flex-col items-start pb-10 overflow-y-auto">
        <h1 className="text-black text-2xl font-semibold">
          Create A How To Post
        </h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col pt-6 gap-y-3"
        >
          <h3 className="font-semibold text-black text-xl">1. General Data</h3>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Title</label>
              <InputField
                name="title"
                placeholder="Title"
                type="text"
                errors={formik.errors.title}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Description</label>
              <InputField
                name="description"
                placeholder="Description"
                type="text"
                errors={formik.errors.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start px-1">
              <label className="text-lg font-medium">Difficulty Level</label>
              <Select
                placeholder="Difficulty Level"
                className="min-h-[48px] w-full"
                suffixIcon={<CaretDownOutlined className="text-xl" />}
                options={DIFFICULTY_LEVEL_OPTIONS}
                onSelect={(value: any) => {
                  formik.setFieldValue("difficulty_level", value);
                }}
              />
              {formik.errors.difficulty_level && (
                <p className="text-[red]">{formik.errors.difficulty_level}</p>
              )}
            </div>
          </div>
          <h3 className="text-black font-semibold text-xl">2. Steps</h3>
          <button
            onClick={() => {
              if (steps.length === stepsCount - 1 && currentStep === null) {
                return;
              }
              if (currentStep === null) {
                setStepsCount((count: any) => count + 1);
              }
            }}
            className="bg-main rounded-md px-5 py-2 flex items-center justify-center text-white font-medium gap-x-2 w-fit"
            type="button"
          >
            <PlusCircleOutlined className="font-bold" />
            Add New Step
          </button>
          <div className="w-full flex flex-col gap-y-4">
            {Array(stepsCount)
              .fill(0)
              .map((_, idx: number) => {
                return (
                  <div className="w-full flex gap-x-4 items-end">
                    <div className="w-1/2 flex flex-col items-start">
                      <label className="text-lg font-medium">Text</label>
                      <InputField
                        name="text"
                        placeholder="Text"
                        type="text"
                        errors={null}
                        disabled={
                          steps.find((item: Step) => item?.id === idx)
                            ? true
                            : false
                        }
                        onChange={(value: any) => {
                          if (currentStep === null) {
                            const step: Step = {
                              id: idx,
                              text: value.target.value,
                            };
                            setCurrentStep(step);
                          } else {
                            const step: Step = currentStep as Step;
                            step.text = value.target.value;
                            setCurrentStep(step);
                          }
                        }}
                      />
                    </div>
                    <div className="w-1/2 flex flex-col items-start">
                      <label className="text-lg font-medium">Picture</label>
                      <InputField
                        name="text"
                        placeholder="Text"
                        type="file"
                        errors={null}
                        disabled={
                          steps.find((item: Step) => item?.id === idx)
                            ? true
                            : false
                        }
                        className="pt-2"
                        onChange={(e: any) => {
                          if (e.target.files) {
                            if (currentStep !== null) {
                              const step: Step = currentStep as Step;
                              step.picture = e.target.files[0];
                              setCurrentStep(step);
                            } else {
                              const step: Step = {
                                id: idx,
                                picture: e.target.files[0],
                              };
                              setCurrentStep(step);
                            }
                          }
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      disabled={
                        steps.find((item: Step) => item?.id === idx)
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (
                          currentStep === null ||
                          !Object.hasOwn(currentStep, "picture") ||
                          currentStep?.text === ""
                        ) {
                          notification.error({
                            message:
                              "Please make sure to enter a step's text and picture",
                          });
                          return;
                        }
                        setSteps((prev: Step[]) => [
                          ...prev,
                          currentStep as Step,
                        ]);
                        setCurrentStep(null);
                      }}
                      className="bg-green-400 p-2 px-3 rounded-md h-full"
                    >
                      <PlusCircleOutlined className="text-white" />
                    </button>
                    {idx !== 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          const newSteps = steps.filter(
                            (_, i: number) => i !== idx
                          );
                          setSteps(newSteps);
                          setStepsCount((count: any) => count - 1);
                        }}
                        className="bg-[red] p-2 px-3 rounded-md h-full"
                      >
                        <MinusCircleOutlined className="text-white" />
                      </button>
                    )}
                  </div>
                );
              })}
          </div>
          <h3 className="text-black font-semibold text-xl">3. Tips</h3>
          <button
            onClick={() => {
              if (tips.length === tipsCount - 1 && currentTip === null) {
                return;
              }
              if (currentTip === null) {
                setTipsCount((count: any) => count + 1);
              }
            }}
            className="bg-main rounded-md px-5 py-2 flex items-center justify-center text-white font-medium gap-x-2 w-fit"
            type="button"
          >
            <PlusCircleOutlined className="font-bold" />
            Add New Tip
          </button>
          <div className="w-full flex flex-col gap-y-4">
            {Array(tipsCount)
              .fill(0)
              .map((_, idx: number) => {
                return (
                  <div className="w-full flex gap-x-4 items-end">
                    <div className="w-1/2 flex flex-col items-start">
                      <label className="text-lg font-medium">Text</label>
                      <InputField
                        name="text"
                        placeholder="Text"
                        type="text"
                        errors={null}
                        disabled={
                          tips.find((item: Tip) => item?.id === idx)
                            ? true
                            : false
                        }
                        onChange={(value: any) => {
                          if (currentTip === null) {
                            const tip: Tip = {
                              id: idx,
                              text: value.target.value,
                            };
                            setCurrentTip(tip);
                          } else {
                            const tip: Tip = currentTip as Tip;
                            tip.text = value.target.value;
                            setCurrentTip(tip);
                          }
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      disabled={
                        tips.find((item: Tip) => item?.id === idx)
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (currentTip === null || currentTip.text === "") {
                          notification.error({
                            message: "Please make sure to enter the tip's text",
                          });
                          return;
                        }
                        setTips((prev: any) => [...prev, currentTip]);
                        setCurrentTip(null);
                      }}
                      className="bg-green-400 p-2 px-3 rounded-md h-full"
                    >
                      <PlusCircleOutlined className="text-white" />
                    </button>
                    {idx !== 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          const newTips = tips.filter(
                            (_, i: number) => i !== idx
                          );
                          setTips(newTips);
                          setTipsCount((count: any) => count - 1);
                        }}
                        className="bg-[red] p-2 px-3 rounded-md h-full"
                      >
                        <MinusCircleOutlined className="text-white" />
                      </button>
                    )}
                  </div>
                );
              })}
          </div>
          <LoadingButton
            body="Create"
            loading={createLoading}
            className="w-1/4 mt-10"
          />
        </form>
      </div>
    </section>
  );
};

export default CreateHowToPost;
