import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const UsersTableModel = (
  setModalOpen: any, 
  setSuspendingId: any,
  setActivateModalOpen: any,
  setActivatingId: any
) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Phone",
    dataIndex: "phone_number",
    key: "phone_number",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Status",
    dataIndex: "account_status",
    key: "account_status",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>Suspend User</p>}>
            <button
              className="bg-[red] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                setModalOpen(true);
                setSuspendingId(value);
              }}
            >
              <StopOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Activate User</p>}>
            <button
              className="bg-green-400 p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                setActivateModalOpen(true);
                setActivatingId(value);
              }}
            >
              <CheckCircleOutlined className="text-white" />
            </button>
          </Popover>
        </div>
      );
    },
  },
];

export default UsersTableModel;
