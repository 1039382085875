import { useFormik } from "formik";
import * as yup from "yup";
import CreateVendorModel from "model/CreateVendorModel";
import { DEFAULT_ERROR_MESSAGE } from "constants/index";
import { useDispatch } from "react-redux";
import { createItemAction } from "redux/actions/createItemAction";
import { createVendorEndpoint } from "api/apiUrls";
import { useNavigate } from "react-router";

const useCreateVendorForm = () => {
    const navigate = useNavigate();

    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            phone_number: '',
            category: '',
            address: '',
            base_fee: 0,
            rating: 0,
            description: '',
            profile_picture: null
        },
        validationSchema: yup.object({
            name: yup.string().required(DEFAULT_ERROR_MESSAGE),
            phone_number: yup.string().required(DEFAULT_ERROR_MESSAGE).matches(/^(011|012|015|010)\d{8}$/, 'Phone number must start with 011, 012, 015, or 010 and be 11 digits long'),
            category: yup.string().required(DEFAULT_ERROR_MESSAGE),
            address: yup.string().required(DEFAULT_ERROR_MESSAGE),
            base_fee: yup.number().required(DEFAULT_ERROR_MESSAGE),
            rating: yup.number().required(DEFAULT_ERROR_MESSAGE).max(5.0, 'Rating can have a maximum of 5.0'),
            description: yup.string().required(DEFAULT_ERROR_MESSAGE)
        }),
        onSubmit: async (values: CreateVendorModel) => {
            const formData: any =  {
                name: values.name,
                phone_number: values.phone_number,
                category: values.category,
                address: values.address,
                base_fee: values.base_fee,
                rating: values.rating,
                description: values.description
            }

            if (values.profile_picture !== null) {
                formData.profile_picture = values.profile_picture;
            }

            await dispatch(createItemAction(createVendorEndpoint, formData, () => {
                navigate(-1);
            }));
        }
    });

    return formik;
}

export {
    useCreateVendorForm
}