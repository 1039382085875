import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const ReportsTableModel = (
  handleShowClick: any,
  setModalOpen: any,
  setDeletingId: any
) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Reporter Phone",
    dataIndex: "reporter",
    key: "reporter",
    render: (value: any) => {
      return <p>{value?.phone_number}</p>;
    },
  },
  {
    title: "Reporter Username",
    dataIndex: "reporter",
    key: "reporter",
    render: (value: any) => {
      return <p>{value?.username}</p>;
    },
  },
  {
    title: "Reported Phone",
    dataIndex: "reported",
    key: "reported",
    render: (value: any) => {
      return <p>{value?.phone_number}</p>;
    },
  },
  {
    title: "Reported Username",
    dataIndex: "reported",
    key: "reported",
    render: (value: any) => {
      return <p>{value?.username}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>Show Report</p>}>
            <button
              className="bg-[#295e56] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                handleShowClick(value);
              }}
            >
              <EyeOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Delete Report</p>}>
            <button
              className="bg-[red] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                setModalOpen(true);
                setDeletingId(value);
              }}
            >
              <DeleteOutlined className="text-white" />
            </button>
          </Popover>
        </div>
      );
    },
  },
];

export default ReportsTableModel;
