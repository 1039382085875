import { CaretDownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import InputField from "components/Shared/ui/InputField";
import { CATEGORY_DROPDOWN } from "constants/index";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useUpdateVendorForm } from "forms/updateVendorForm";

const ShowVendor = () => {
  const { item } = useSelector(
    (state: RootState) => state.listSingleItemReducer
  );

  const formik = useUpdateVendorForm(item);

  return (
    <section className="w-full h-full px-5 py-5">
      <div className="w-full bg-white max-h-[90%] min-h-[90%] rounded-md shadow-md border border-x-gray-300 px-4 py-4 flex flex-col items-start pb-10 overflow-y-auto relative">
        <h1 className="text-black text-2xl font-semibold">Show Vendor</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col pt-6 gap-y-3"
        >
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Name</label>
              <InputField
                name="name"
                placeholder="Name"
                type="text"
                errors={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start px-1">
              <label className="text-lg font-medium">Category</label>
              <Select
                placeholder="Difficulty Level"
                className="min-h-[48px] w-full"
                suffixIcon={<CaretDownOutlined className="text-xl" />}
                options={CATEGORY_DROPDOWN}
                onSelect={(value: any) => {
                  formik.setFieldValue("category", value);
                }}
                value={formik.values.category}
                disabled
              />
              {formik.errors.category && (
                <p className="text-[red]">{formik.errors.category}</p>
              )}
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Address</label>
              <InputField
                name="address"
                placeholder="Address"
                type="text"
                errors={formik.errors.address}
                onChange={formik.handleChange}
                value={formik.values.address}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Base Fee</label>
              <InputField
                name="base_fee"
                placeholder="Base Fee"
                type="number"
                errors={formik.errors.base_fee}
                onChange={formik.handleChange}
                value={formik.values.base_fee}
                disabled
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Rating</label>
              <InputField
                name="rating"
                placeholder="Rating"
                type="number"
                errors={formik.errors.rating}
                onChange={formik.handleChange}
                value={formik.values.rating}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Description</label>
              <InputField
                name="description"
                placeholder="Description"
                type="text"
                errors={formik.errors.description}
                onChange={formik.handleChange}
                value={formik.values.description}
                disabled
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Phone Number</label>
              <InputField
                name="phone_number"
                placeholder="Phone Number"
                type="text"
                errors={formik.errors.phone_number}
                onChange={formik.handleChange}
                value={formik.values.phone_number}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Profile Picture</label>
              <img
                src={formik.values.profile_picture}
                alt="profile"
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ShowVendor;
