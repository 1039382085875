import styles from "screens/Login/Login.module.css";
import { Logo, LogoTextBlue } from "assets/icons";
import InputField from "components/Shared/ui/InputField";
import LoadingButton from "components/Shared/ui/LoadingButton";
import { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useLoginForm } from "forms/loginForm";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useNavigate } from "react-router";
import { HOME_PATH } from "Routes/paths";

const Login = () => {
  const formik = useLoginForm();

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const { loginLoading, token } = useSelector((state: RootState) => state.loginReducer);

  useEffect(() => {
    if (token) {
      navigate(HOME_PATH);
    }
  }, [token]);

  return (
    <section className="w-full h-full flex flex-row">
      <div className="h-full w-1/2 hidden scrn-950:flex flex-col items-center justify-center bg-main">
        <img src={Logo} alt="logo" />
      </div>
      <div className="h-full w-full scrn-950:w-1/2 flex flex-col items-center justify-center relative">
        <img src={LogoTextBlue} alt="logo" className="absolute top-2 right-5" />
        <div className="w-full scrn-950:w-3/4 flex flex-col items-center scrn-950:items-start gap-y-1">
          <h1 className="text-main font-semibold text-4xl">Welcome !</h1>
          <p className="text-sm text-gray-500">Please enter your details.</p>
          <form
            onSubmit={formik.handleSubmit}
            className="pt-5 w-3/4 flex flex-col gap-y-4 items-start"
          >
            <div className="w-full flex flex-col items-start gap-y-2">
              <label className="font-medium">Username*</label>
              <InputField
                name="username"
                placeholder="Username..."
                onChange={formik.handleChange}
                errors={formik.errors.username}
                type="text"
                className="w-full scrn-518:min-w-[410px]"
              />
            </div>
            <div className="w-full flex flex-col items-start gap-y-2">
              <label className="font-medium">Password*</label>
              <InputField
                name="password"
                placeholder="Password***"
                onChange={formik.handleChange}
                type={showPassword ? "text" : "password"}
                errors={formik.errors.password}
                className="w-full scrn-518:min-w-[410px]"
              />
              <Checkbox
                onChange={(e: CheckboxChangeEvent) =>
                  setShowPassword(e.target.checked)
                }
              >
                Show Password
              </Checkbox>
            </div>
            <LoadingButton
              body="Login"
              loading={loginLoading}
              className="scrn-518:min-w-[250px]"
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
