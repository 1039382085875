import React, { useEffect } from "react";
import Dashboard from "./Dashboard";
import { Outlet, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { LOGIN_PATH } from "Routes/paths";

const DashboardParent = () => {
  const navigate = useNavigate();

  const { token } = useSelector((state: RootState) => state.loginReducer);

  useEffect(() => {
    if (!token) {
      navigate(LOGIN_PATH);
    }
  }, [token]);

  return (
    <Dashboard>
      <Outlet />
    </Dashboard>
  );
};

export default DashboardParent;
