import
{
    UPDATE_ITEM_LOADING,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";

export const updateItemAction = (url: string, id: string, data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_ITEM_LOADING, payload: true});
        const response = await updateItemService(url, id, data);
        dispatch({type: UPDATE_ITEM_SUCCESS, payload: response.data});
        callback && callback();
    }   
    catch (err) {
        dispatch({type: UPDATE_ITEM_FAILURE, payload: err});
    }
    finally {
        dispatch({type: UPDATE_ITEM_LOADING, payload: false});
    }
}