export const loginEndpoint = "/admin/login";

// Users Endpoint
export const listUsersEndpoint = "/admin/allUsers";
export const suspendUserEndpoint = "/admin/change_user_status";

// How To Posts
export const listHowToPostsEndpoint = "/admin/how_to_posts";
export const createHowToPostEndpoint = "/admin/create_how_to_post";
export const deleteHowToPostEndpoint = "/admin";

// Vendors
export const listVendorsEndpoint = "/admin/allVendors";
export const createVendorEndpoint = "/admin/storeVendor";
export const deleteVendorEndpoint = "/admin/delete_vendor";
export const showVendorEndpoint = "/admin/vendor"
export const updateVendorEndpoint = "/admin"

// Vendor Admins
export const listVendorAdminsEndpoint = "/admin/allVendorAdmins";
export const createVendorAdminEndpoint = "/admin/storeVendorAdmin";
export const deleteVendorAdminEndpoint = "/admin/delete_vendor_admin";
export const showVendorAdminEndpoint = "/admin/vendor_admin";
export const updateVendorAdminEndpoint = "/admin/vendor_admin";

// Reports
export const listReportsEndpoint = "/admin/reports";
export const showReportEndpoint = "/admin/reports";

// Posts
export const listPostsEndpoint = "/admin/posts";
export const showPostEndpoint = "/admin/posts";
export const updatePostStatusEndpoint = "/admin/change_post_status"