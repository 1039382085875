import
{
    ITEM_LOADING,
    ITEM_SUCCESS,
    ITEM_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { showItemService } from "services/showItemService";

export const listSingleItemAction = (url: string, id: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: ITEM_LOADING, payload: true});
        const response = await showItemService(url, id);
        dispatch({type: ITEM_SUCCESS, payload: response.data});
        callback && callback(response.data);
    }   
    catch (err) {
        dispatch({type: ITEM_FAILURE, payload: err});
    }
    finally {
        dispatch({type: ITEM_LOADING, payload: false});
    }
}