import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listItemsAction } from "redux/actions/listItemsAction";
import { listUsersEndpoint } from "api/apiUrls";
import { Modal, Table } from "antd";
import { useSearchParams } from "react-router-dom";
import UsersTableModel from "model/tables/UsersTableModel";
import { suspendUserAction } from "redux/actions/userActions/suspendUserAction";

const Index = () => {
  const dispatch: any = useDispatch();

  const [suspendModalOpen, setSuspendModalOpen] = useState(false);
  const [suspendingId, setSuspendingId] = useState("");

  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [activatingId, setActivatingId] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const { listItemsLoading, items, pagination } = useSelector(
    (state: RootState) => state.listItemsReducer
  );
  const { suspendLoading } = useSelector(
    (state: RootState) => state.suspendUserReducer
  );

  useEffect(() => {
    var page = searchParams?.get("page") ?? 1;
    dispatch(listItemsAction(listUsersEndpoint, `page=${page}`));
  }, [searchParams]);

  return (
    <section className="w-full h-full overflow-y-scroll">
      <div className="w-full px-10 pt-10 pb-32 flex flex-col items-end">
        <Modal
          title="Activate User Confirmation"
          open={activateModalOpen}
          onOk={async () => {
            await dispatch(
              suspendUserAction(activatingId, "ACTIVE", async () => {
                setActivateModalOpen(false);
                const page = searchParams?.get("page") ?? 1;
                await dispatch(
                  listItemsAction(listUsersEndpoint, `page=${page}`)
                );
              })
            );
          }}
          onCancel={() => setActivateModalOpen(false)}
          okButtonProps={{
            className: "bg-green-400 hover:!bg-green-400 hover:opacity-50",
          }}
          className="w-full"
          confirmLoading={suspendLoading}
        >
          <p>Are you sure you want to activate: {suspendingId}</p>
        </Modal>
        <Modal
          title="Suspend User Confirmation"
          open={suspendModalOpen}
          onOk={async () => {
            await dispatch(
              suspendUserAction(suspendingId, "SUSPENDED", async () => {
                setSuspendModalOpen(false);
                const page = searchParams?.get("page") ?? 1;
                await dispatch(
                  listItemsAction(listUsersEndpoint, `page=${page}`)
                );
              })
            );
          }}
          onCancel={() => setSuspendModalOpen(false)}
          okButtonProps={{
            className: "bg-[red] hover:!bg-[red] hover:opacity-50",
          }}
          className="w-full"
          confirmLoading={suspendLoading}
        >
          <p>Are you sure you want to suspend: {suspendingId}</p>
        </Modal>
        <Table
          className="m-4 shadow-md rounded-md w-[97%] border border-gray-300 overflow-x-auto"
          dataSource={items}
          loading={listItemsLoading}
          tableLayout="auto"
          columns={UsersTableModel(
            setSuspendModalOpen,
            setSuspendingId,
            setActivateModalOpen,
            setActivatingId
          )}
          pagination={{
            current:
              Number(searchParams.get("page")) === 0
                ? 1
                : Number(searchParams.get("page")),
            pageSize: 8,
            total: Number(pagination?.last_page) * 8,
            showSizeChanger: false,
            onChange: (page: number) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            },
          }}
        />
      </div>
    </section>
  );
};

export default Index;
