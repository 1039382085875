import { CaretDownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import InputField from "components/Shared/ui/InputField";
import { useCreateVendorForm } from "forms/createVendorForm";
import { CATEGORY_DROPDOWN } from "constants/index";
import LoadingButton from "components/Shared/ui/LoadingButton";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

const CreateVendor = () => {
  const formik = useCreateVendorForm();

  const { createLoading } = useSelector(
    (state: RootState) => state.createItemReducer
  );

  return (
    <section className="w-full h-full px-5 py-5">
      <div className="w-full bg-white max-h-[90%] min-h-[90%] rounded-md shadow-md border border-x-gray-300 px-4 py-4 flex flex-col items-start pb-10 overflow-y-auto relative">
        <h1 className="text-black text-2xl font-semibold">Create A Vendor</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col pt-6 gap-y-3"
        >
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Name</label>
              <InputField
                name="name"
                placeholder="Name"
                type="text"
                errors={formik.errors.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-1/2 flex flex-col items-start px-1">
              <label className="text-lg font-medium">Category</label>
              <Select
                placeholder="Difficulty Level"
                className="min-h-[48px] w-full"
                suffixIcon={<CaretDownOutlined className="text-xl" />}
                options={CATEGORY_DROPDOWN}
                onSelect={(value: any) => {
                  formik.setFieldValue("category", value);
                }}
              />
              {formik.errors.category && (
                <p className="text-[red]">{formik.errors.category}</p>
              )}
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Address</label>
              <InputField
                name="address"
                placeholder="Address"
                type="text"
                errors={formik.errors.address}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Base Fee</label>
              <InputField
                name="base_fee"
                placeholder="Base Fee"
                type="number"
                errors={formik.errors.base_fee}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Rating</label>
              <InputField
                name="rating"
                placeholder="Rating"
                type="number"
                errors={formik.errors.rating}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Description</label>
              <InputField
                name="description"
                placeholder="Description"
                type="text"
                errors={formik.errors.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Phone Number</label>
              <InputField
                name="phone_number"
                placeholder="Phone Number"
                type="text"
                errors={formik.errors.phone_number}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Profile Picture</label>
              <InputField
                name="profile_picture"
                placeholder="Profile Picture"
                type="file"
                errors={formik.errors.profile_picture}
                onChange={(e: any) => {
                  if (e.target.files) {
                    formik.setFieldValue("profile_picture", e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
          <LoadingButton
            body="Create"
            loading={createLoading}
            className="absolute bottom-3 w-1/4"
          />
        </form>
      </div>
    </section>
  );
};

export default CreateVendor;
