import
{
    CREATE_ITEM_LOADING,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createItemService } from "services/createItemService";

export const createItemAction = (url: string, data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CREATE_ITEM_LOADING, payload: true});
        const response = await createItemService(url, data);
        dispatch({type: CREATE_ITEM_SUCCESS, payload: response.data});
        callback && callback();
    }   
    catch (err) {
        dispatch({type: CREATE_ITEM_FAILURE, payload: err});
    }
    finally {
        dispatch({type: CREATE_ITEM_LOADING, payload: false});
    }
}