import
{
    DELETE_ITEM_LOADING,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE
}
from "redux/types";

const initialState = {
    deleteLoading: false,
    success: null,
    errors: null
}

export const deleteItemReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_ITEM_LOADING :
            return {...state, deleteLoading: action.payload};
        case DELETE_ITEM_SUCCESS :
            return {...state, success: action.payload};
        case DELETE_ITEM_FAILURE :
            return {...state, errors: null};
        default:
            return state;
    }
}