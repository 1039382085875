import React, { FC } from "react";
import Sidebar from "components/Sidebar/Sidebar";
import Navbar from "components/Navbar/Navbar";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

type DashboardProps = {
  children: React.ReactNode;
};

const Dashboard: FC<DashboardProps> = ({ children }) => {
  const { listItemsLoading } = useSelector(
    (state: RootState) => state.listItemsReducer
  );
  const { itemLoading } = useSelector(
    (state: RootState) => state.listSingleItemReducer
  );

  return (
    <Spin
      wrapperClassName="spin-full-width"
      indicator={<LoadingOutlined spin style={{ fontSize: 48 }} />}
      spinning={listItemsLoading || itemLoading}
    >
      <div className="w-full h-full flex">
        <aside className="w-[300px] h-full bg-main">
          <Sidebar />
        </aside>
        <div
          style={{
            width: "calc(100% - 300px)",
          }}
          className="h-full relative"
        >
          <nav className="absolute top-0 w-full h-[80px] bg-main border-white border-l-2">
            <Navbar />
          </nav>
          <section className="mt-[80px] w-full h-full">{children}</section>
        </div>
      </div>
    </Spin>
  );
};

export default Dashboard;
