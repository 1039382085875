import 
{
    SET_VENDOR_ID
}
from "redux/types";

const initialState = {
    vendorId: null
}

export const setVendorIdReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_VENDOR_ID :
            return {...state, vendorId: action.payload};
        default:
            return state;
    }
}