import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";

const VendorAdminTableModel = (
  handleView: any,
  handleEdit: any,
  setModalOpen: any,
  setDeletingId: any
) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    key: "vendor",
    render: (value: any) => {
      return <p>{value?.name}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>View</p>}>
            <button
              className="bg-[#295e56] p-2 flex items-center justify-center rounded-md"
              onClick={() => handleView(value)}
            >
              <EyeOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Edit</p>}>
            <button
              className="bg-[#00cfe8] p-2 flex items-center justify-center rounded-md"
              onClick={() => handleEdit(value)}
            >
              <EditOutlined className="text-white" />
            </button>
          </Popover>
          <Popover content={<p>Delete</p>}>
            <button
              className="bg-[red] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                setModalOpen(true);
                setDeletingId(value);
              }}
            >
              <DeleteOutlined className="text-white" />
            </button>
          </Popover>
        </div>
      );
    },
  },
];

export default VendorAdminTableModel;
