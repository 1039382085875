import { useFormik } from "formik"
import * as yup from "yup";
import CreateVendorAdminModel from "model/CreateVendorAdminModel";
import { DEFAULT_ERROR_MESSAGE } from "constants/index";
import { useDispatch } from "react-redux";
import { createItemAction } from "redux/actions/createItemAction";
import { createVendorAdminEndpoint } from "api/apiUrls";
import { useNavigate } from "react-router";

const useCreateVendorAdminForm = (vendorId: string) => {
    const dispatch: any = useDispatch();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            vendor: vendorId,
            username: '',
            name: '',
            password: ''
        },
        validationSchema: yup.object({
            username: yup.string().required(DEFAULT_ERROR_MESSAGE),
            name: yup.string().required(DEFAULT_ERROR_MESSAGE),
            password: yup.string().required(DEFAULT_ERROR_MESSAGE).min(8, 'Password has to be at least 8 characters')
        }),
        onSubmit: async (values: CreateVendorAdminModel) => {
            const formData: any = {
                vendor: values.vendor,
                username: values.username,
                name: values.name,
                password: values.password
            };

            await dispatch(createItemAction(createVendorAdminEndpoint, formData, () => {
                navigate(-1);
            }));
        }
    });

    return formik;
}

export {
    useCreateVendorAdminForm
}