import { combineReducers } from "redux";
import { loginReducer } from "redux/reducers/loginReducer";
import { listItemsReducer } from "redux/reducers/listItemsReducer";
import { createItemReducer } from "redux/reducers/createItemReducer";
import { updateItemReducer } from "redux/reducers/updateItemReducer";
import { deleteItemReducer } from "redux/reducers/deleteItemReducer";
import { listSingleItemReducer } from "redux/reducers/listSingleItemReducer";
import { setVendorIdReducer } from "redux/reducers/vendorReducers/setVendorIdReducer";
import { suspendUserReducer } from "redux/reducers/userReducers/suspendUserReducer";

export const appReducer = combineReducers({
  loginReducer,
  listItemsReducer,
  createItemReducer,
  updateItemReducer,
  deleteItemReducer,
  listSingleItemReducer,
  setVendorIdReducer,
  suspendUserReducer
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
