import
{
    LIST_ITEMS_LOADING,
    LIST_ITEMS_SUCCESS,
    LIST_ITEMS_FAILURE
}
from "redux/types";

const initialState = {
    listItemsLoading: false,
    items: null,
    pagination: null,
    errors: null
}

export const listItemsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_ITEMS_LOADING :
            return {...state, listItemsLoading: action.payload};
        case LIST_ITEMS_SUCCESS :
            return {...state, items: action.payload.data.data, pagination: action.payload.data.pagination};
        case LIST_ITEMS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}