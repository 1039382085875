import
{
    SUSPEND_USER_LOADING,
    SUSPEND_USER_SUCCESS,
    SUSPEND_USER_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";
import { suspendUserEndpoint } from "api/apiUrls";

export const suspendUserAction = (userId: string, status: "SUSPENDED"| "ACTIVE", callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: SUSPEND_USER_LOADING, payload: true});
        const response = await updateItemService(suspendUserEndpoint, userId, {
            account_status: status
        });
        dispatch({type: SUSPEND_USER_SUCCESS, payload: response.data});
        callback && callback();
    }   
    catch (err) {
        dispatch({type: SUSPEND_USER_FAILURE, payload: err});
    }
    finally {
        dispatch({type: SUSPEND_USER_LOADING, payload: false});
    }
}