import { Input } from "antd";
import { FC } from "react";

type InputFieldProps = {
  name: string;
  placeholder: string;
  type: "text" | "password" | "file" | "number";
  onChange: any;
  errors: any;
  className?: string;
  disabled?: boolean;
  value?: any;
};

const InputField: FC<InputFieldProps> = ({
  name,
  placeholder,
  type,
  onChange,
  errors,
  className,
  disabled = false,
  value,
}) => {
  return (
    <>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        className={`min-h-[48px] ${className}`}
        status={errors ? "error" : ""}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      {errors && <p className="text-[red]">{errors}</p>}
    </>
  );
};

export default InputField;
