import { CaretDownOutlined } from "@ant-design/icons";
import { Checkbox, Modal, Select, Spin } from "antd";
import InputField from "components/Shared/ui/InputField";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { POST_STATUS_DROPDOWN } from "constants/index";
import { useDispatch } from "react-redux";
import { updateItemAction } from "redux/actions/updateItemAction";
import { showPostEndpoint, updatePostStatusEndpoint } from "api/apiUrls";
import { listSingleItemAction } from "redux/actions/listSingleItemAction";
import { useState } from "react";

const ShowPost = () => {
  const dispatch: any = useDispatch();

  const [rejectStatusModalOpen, setRejectStatusModalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [rejectReasonError, setRejectReasonError] = useState("");

  const { item, itemLoading } = useSelector(
    (state: RootState) => state.listSingleItemReducer
  );

  const { updateLoading } = useSelector(
    (state: RootState) => state.updateItemReducer
  );

  return (
    <section className="w-full h-full px-5 py-5">
      <Modal
        open={rejectStatusModalOpen}
        onCancel={() => setRejectStatusModalOpen(false)}
        title="Reject Reason"
        okButtonProps={{
          style: {
            backgroundColor: "#4553FF",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        confirmLoading={updateLoading}
        onOk={async () => {
          if (rejectReason?.trim() === "") {
            setRejectReasonError("Please enter a reject reason");
          } else {
            setRejectReasonError("");
            await dispatch(
              updateItemAction(updatePostStatusEndpoint, item?.id, {
                post_status: "REJECTED",
                reason_for_rejection: rejectReason,
              })
            );
            setRejectStatusModalOpen(false);
            await dispatch(listSingleItemAction(showPostEndpoint, item?.id));
          }
        }}
        className="w-full flex flex-col items-start gap-y-4"
      >
        <label className="font-medium">
          Enter the reject reason for the post
        </label>
        <InputField
          name="reject_reason"
          placeholder="Reject Reason"
          type="text"
          errors={rejectReasonError?.trim() !== "" ? rejectReasonError : null}
          onChange={(e: any) => setRejectReason(e.target.value)}
          value={rejectReason}
        />
      </Modal>
      <div className="w-full bg-white max-h-[90%] min-h-[90%] rounded-md shadow-md border border-x-gray-300 px-4 py-4 flex flex-col items-start pb-10 overflow-y-auto relative">
        <h1 className="text-black text-2xl font-semibold">Show Post</h1>
        <Spin
          spinning={updateLoading || itemLoading}
          wrapperClassName="spin-full-width"
        >
          <form
            onSubmit={() => {}}
            className="w-full flex flex-col pt-6 gap-y-3"
          >
            <div className="w-full flex flex-col items-start gap-2">
              <h3 className="text-3xl">User Data</h3>
              <div className="w-full h-[2px] bg-black" />
            </div>
            <div className="w-full flex justify-between gap-x-4">
              <div className="w-1/2 flex flex-col items-start">
                <label className="text-lg font-medium">Username</label>
                <InputField
                  name="username"
                  placeholder="Username"
                  type="text"
                  errors={null}
                  onChange={null}
                  value={item?.user?.username}
                  disabled
                />
              </div>
              <div className="w-1/2 flex flex-col items-start px-1">
                <label className="text-lg font-medium">Full Name</label>
                <InputField
                  name="name"
                  placeholder="Full Name"
                  type="text"
                  errors={null}
                  onChange={null}
                  value={item?.user?.name}
                  disabled
                />
              </div>
            </div>
            <div className="w-full flex justify-between gap-x-4">
              <div className="w-1/2 flex flex-col items-start">
                <label className="text-lg font-medium">Profile Picture</label>
                <img
                  src={item?.user?.profile_picture}
                  alt=""
                  className="w-64 h-64 object-cover"
                />
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <h3 className="text-3xl">Post Data</h3>
              <div className="w-full h-[2px] bg-black" />
            </div>
            <div className="w-full flex justify-between gap-x-4">
              <div className="w-1/2 flex flex-col items-start">
                <label className="text-lg font-medium">Caption</label>
                <InputField
                  name="caption"
                  placeholder="Caption"
                  type="text"
                  errors={null}
                  onChange={null}
                  value={
                    item?.caption?.trim() === "" || !item?.caption
                      ? "No Caption"
                      : item?.caption
                  }
                  disabled
                />
              </div>
              <div className="w-1/2 flex flex-col items-start">
                <label className="text-lg font-medium">Picture</label>
                <img
                  src={item?.picture}
                  alt=""
                  className="w-64 h-64 object-cover"
                />
              </div>
            </div>
            <div className="w-full flex justify-between gap-x-4">
              <div className="w-1/2 flex flex-col items-start">
                <label className="text-lg font-medium">Comments Allowed</label>
                <Checkbox
                  disabled
                  checked={item.comments_allowed === 1 ? true : false}
                >
                  Comments Allowed
                </Checkbox>
              </div>
              <div className="w-1/2 flex flex-col items-start">
                <label className="text-lg font-medium">Post Status</label>
                <Select
                  placeholder="Post Status"
                  className="min-h-[48px] w-full"
                  suffixIcon={<CaretDownOutlined className="text-xl" />}
                  options={POST_STATUS_DROPDOWN}
                  onSelect={async (value: any) => {
                    if (value !== "REJECTED") {
                      await dispatch(
                        updateItemAction(updatePostStatusEndpoint, item?.id, {
                          post_status: value,
                        })
                      );
                      dispatch(
                        listSingleItemAction(showPostEndpoint, item?.id)
                      );
                    } else {
                      setRejectStatusModalOpen(true);
                    }
                  }}
                  value={item?.status}
                />
              </div>
            </div>
            {item?.reason_for_rejection?.trim() !== "" &&
              item?.reason_for_rejection && (
                <div className="w-1/2 flex flex-col items-start">
                  <label className="text-lg font-medium">Reject Reason</label>
                  <InputField
                    name="reject_reason"
                    placeholder="Reject Reason"
                    type="text"
                    errors={null}
                    onChange={null}
                    value={item?.reason_for_rejection}
                    disabled
                  />
                </div>
              )}
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default ShowPost;
