import api from "api";
import store from "redux/store";

export const showItemService = async (url: string, id: string) => {
    const response = await api.get(
        url + `/${id}`,
        {
            headers: {
                "Authorization" : `Bearer ${store.getState()?.loginReducer?.token}`
            }
        }
    );
    
    return response;
}