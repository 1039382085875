import
{
    ITEM_LOADING,
    ITEM_SUCCESS,
    ITEM_FAILURE
}
from "redux/types";

const initialState = {
    itemLoading: false,
    item: null,
    errors: null
}

export const listSingleItemReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ITEM_LOADING :
            return {...state, itemLoading: action.payload};
        case ITEM_SUCCESS :
            return {...state, item: action.payload.data};
        case ITEM_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}