import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { DEFAULT_ERROR_MESSAGE } from "constants/index";
import * as yup from "yup";
import CreateVendorAdminModel from "model/CreateVendorAdminModel";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { updateItemAction } from "redux/actions/updateItemAction";
import { updateVendorAdminEndpoint } from "api/apiUrls";

const useUpdateVendorAdminForm = (vendorId: string) => {
    const dispatch: any = useDispatch();

    const { item } = useSelector((state: RootState) => state.listSingleItemReducer);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            vendor: vendorId,
            username: item?.username,
            name: item?.name,
            password: item?.password
        },
        validationSchema: yup.object({
            username: yup.string().required(DEFAULT_ERROR_MESSAGE),
            name: yup.string().required(DEFAULT_ERROR_MESSAGE),
            password: yup.string().optional().min(8, 'Password has to be at least 8 characters')
        }),
        onSubmit: async (values: CreateVendorAdminModel) => {
            const formData: any = {
                username: values.username,
                name: values.name,
            };

            if (values.password?.trim() !== "") {
                formData.password = values.password;
            }

            await dispatch(updateItemAction(updateVendorAdminEndpoint, item?.id, formData, () => {
                navigate(-1);
            }));
        }
    });

    return formik;
}

export {
    useUpdateVendorAdminForm
}