import
{
    SUSPEND_USER_LOADING,
    SUSPEND_USER_SUCCESS,
    SUSPEND_USER_FAILURE
}
from "redux/types";

const initialState = {
    suspendLoading: false,
    success: null,
    errors: null
}

export const suspendUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SUSPEND_USER_LOADING :
            return {...state, suspendLoading: action.payload};
        case SUSPEND_USER_SUCCESS :
            return {...state, success: action.payload};
        case SUSPEND_USER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}