import { DeleteOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const HowToPostsTableModel = (
  handleView: any,
  handleEdit: any,
  setModalOpen: any,
  setDeletingId: any,
  handleViewStepsClick: any,
  handleViewTipsClick: any
) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Difficulty Level",
    dataIndex: "difficulty_level",
    key: "difficulty_level",
    render: (value: any) => {
      return <p>{value} / 5</p>;
    },
  },
  {
    title: "Steps",
    dataIndex: "steps",
    key: "steps",
    render: (value: any) => {
      return (
        <button
          className="text-black duration-150 transition-all hover:text-main"
          onClick={() => handleViewStepsClick(value)}
        >
          View Steps
        </button>
      );
    },
  },
  {
    title: "Tips",
    dataIndex: "tips",
    key: "tips",
    render: (value: any) => {
      return (
        <button
          className="text-black duration-150 transition-all hover:text-main"
          onClick={() => handleViewTipsClick(value)}
        >
          View Tips
        </button>
      );
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <Popover content={<p>Delete</p>}>
            <button
              className="bg-[red] p-2 flex items-center justify-center rounded-md"
              onClick={() => {
                setModalOpen(true);
                setDeletingId(value);
              }}
            >
              <DeleteOutlined className="text-white" />
            </button>
          </Popover>
        </div>
      );
    },
  },
];

export default HowToPostsTableModel;
