import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listItemsAction } from "redux/actions/listItemsAction";
import { deleteHowToPostEndpoint, listHowToPostsEndpoint } from "api/apiUrls";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal, Table } from "antd";
import HowToPostsTableModel from "model/tables/HowToPostsTableModel";
import { PlusCircleOutlined } from "@ant-design/icons";
import { CREATE_HOW_TO_POST } from "Routes/paths";
import { deleteItemAction } from "redux/actions/deleteItemAction";

const HowToPostsIndex = () => {
  const navigate = useNavigate();

  const { listItemsLoading, items, pagination } = useSelector(
    (state: RootState) => state.listItemsReducer
  );
  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteItemReducer
  );

  const dispatch: any = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState("");

  const [steps, setSteps] = useState<any>([]);
  const [stepsModalOpen, setStepsModalOpen] = useState(false);

  const [tips, setTips] = useState<any>([]);
  const [tipsModalOpen, setTipsModalOpen] = useState(false);

  useEffect(() => {
    const page = searchParams?.get("page") ?? 1;
    dispatch(listItemsAction(listHowToPostsEndpoint, `page=${page}`));
  }, [searchParams]);

  return (
    <section className="w-full h-full overflow-y-scroll">
      <div className="w-full px-10 pt-10 pb-32 flex flex-col items-end">
        <button
          onClick={() => navigate(CREATE_HOW_TO_POST)}
          className="bg-main rounded-md px-5 py-2 flex items-center justify-center text-white font-medium gap-x-2"
        >
          <PlusCircleOutlined className="font-bold" />
          Create New Item
        </button>
        <Modal
          title="Delete Confirmation"
          open={deleteModalOpen}
          onOk={async () => {
            await dispatch(
              deleteItemAction(deleteHowToPostEndpoint, deletingId)
            );
            setDeleteModalOpen(false);
            const page = searchParams?.get("page") ?? 1;
            await dispatch(
              listItemsAction(listHowToPostsEndpoint, `page=${page}`)
            );
          }}
          onCancel={() => setDeleteModalOpen(false)}
          okButtonProps={{
            className: "bg-[red] hover:!bg-[red] hover:opacity-50",
          }}
          className="w-full"
          confirmLoading={deleteLoading}
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
        <Modal
          open={stepsModalOpen}
          onCancel={() => setStepsModalOpen(false)}
          title="Steps"
          okButtonProps={{
            style: {
              display: "none",
            },
          }}
          cancelButtonProps={{
            style: {
              display: "none",
            },
          }}
          className="w-full flex flex-col items-start gap-y-4"
        >
          {steps.map((item: any) => {
            return (
              <div className="flex flex-col items-start gap-y-2">
                <p className="font-bold">{item.id}:</p>
                <p>{item.text}</p>
                <img src={item.picture} alt="step" />
              </div>
            );
          })}
        </Modal>
        <Modal
          open={tipsModalOpen}
          onCancel={() => setTipsModalOpen(false)}
          title="Tips"
          okButtonProps={{
            style: {
              display: "none",
            },
          }}
          cancelButtonProps={{
            style: {
              display: "none",
            },
          }}
          className="w-full flex flex-col items-start gap-y-4"
        >
          {tips.map((item: any) => {
            return (
              <div className="flex flex-col items-start gap-y-2">
                <p className="font-bold">{item.id}:</p>
                <p>{item.text}</p>
              </div>
            );
          })}
        </Modal>
        <Table
          className="m-4 shadow-md rounded-md w-[97%] border border-gray-300 overflow-x-auto"
          dataSource={items}
          loading={listItemsLoading}
          tableLayout="auto"
          columns={HowToPostsTableModel(
            async (id: string) => {
              // await dispatch(
              //   listSingleItemAction("blogs", id, () => {
              //     navigate(`/dashboard/blogs/${id}`);
              //   })
              // );
            },
            async (id: string) => {
              // await dispatch(
              //   listSingleItemAction("blogs", id, () => {
              //     navigate(EDIT_BLOG_PATH);
              //   })
              // );
            },
            setDeleteModalOpen,
            setDeletingId,
            (data: any) => {
              setSteps(data);
              setStepsModalOpen(true);
            },
            (data: any) => {
              setTips(data);
              setTipsModalOpen(true);
            }
          )}
          pagination={{
            current:
              Number(searchParams.get("page")) === 0
                ? 1
                : Number(searchParams.get("page")),
            pageSize: 8,
            total: Number(pagination?.last_page) * 8,
            showSizeChanger: false,
            onChange: (page: number) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            },
          }}
        />
      </div>
    </section>
  );
};

export default HowToPostsIndex;
