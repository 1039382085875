import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import InputField from "components/Shared/ui/InputField";
import { useCreateVendorAdminForm } from "forms/createVendorAdminForm";
import LoadingButton from "components/Shared/ui/LoadingButton";

const CreateVendorAdmin = () => {
  const { vendorId } = useSelector(
    (state: RootState) => state.setVendorIdReducer
  );

  const { createLoading } = useSelector(
    (state: RootState) => state.createItemReducer
  );

  const formik = useCreateVendorAdminForm(vendorId);

  return (
    <section className="w-full h-full px-5 py-5">
      <div className="w-full bg-white max-h-[90%] min-h-[90%] rounded-md shadow-md border border-x-gray-300 px-4 py-4 flex flex-col items-start pb-10 overflow-y-auto relative">
        <h1 className="text-black text-2xl font-semibold">
          Create A Vendor Admin
        </h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col pt-6 gap-y-3"
        >
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Vendor</label>
              <InputField
                name="vendor"
                placeholder="Vendor"
                type="text"
                errors={formik.errors.vendor}
                onChange={formik.handleChange}
                value={formik.values.vendor}
                disabled
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Username</label>
              <InputField
                name="username"
                placeholder="Username"
                type="text"
                errors={formik.errors.username}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="w-full flex justify-between gap-x-4">
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Name</label>
              <InputField
                name="name"
                placeholder="Name"
                type="text"
                errors={formik.errors.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-1/2 flex flex-col items-start">
              <label className="text-lg font-medium">Password</label>
              <InputField
                name="password"
                placeholder="Password*"
                type="password"
                errors={formik.errors.password}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <LoadingButton
            body="Create"
            loading={createLoading}
            className="w-1/4 absolute bottom-4"
          />
        </form>
      </div>
    </section>
  );
};

export default CreateVendorAdmin;
