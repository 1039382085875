import { DashboardIcon, DashboardWhiteIcon, HowToIcon, HowToWhiteIcon, PostsIcon, PostsWhiteIcon, ReportIcon, ReportWhiteIcon, UsersIcon, UsersWhiteIcon, VendorAdminIcon, VendorAdminWhiteIcon, VendorIcon, VendorWhiteIcon } from "assets/icons";

export const DEFAULT_ERROR_MESSAGE = "please enter this field";

export type SIDEBAR_ITEM_PROPS = {
    label: string;
    route: string;
    icon: string;
    selectedIcon: string;
}

export const SIDEBAR_ITEMS: SIDEBAR_ITEM_PROPS[] = [
    {
        label: 'Dashboard',
        route: '/',
        icon: DashboardWhiteIcon,
        selectedIcon: DashboardIcon
    },
    {
        label: 'Users',
        route: '/users',
        icon: UsersWhiteIcon,
        selectedIcon: UsersIcon
    },
    {
        label: 'How To Posts',
        route: '/how-to-posts',
        icon: HowToWhiteIcon,
        selectedIcon: HowToIcon
    },
    {
        label: 'Vendors',
        route: '/vendors',
        icon: VendorWhiteIcon,
        selectedIcon: VendorIcon
    },
    {
        label: 'Vendor Admins',
        route: '/vendors/admins',
        icon: VendorAdminWhiteIcon,
        selectedIcon: VendorAdminIcon
    },
    {
        label: 'Reports',
        route: '/reports',
        icon: ReportWhiteIcon,
        selectedIcon: ReportIcon
    },
    {
        label: 'Posts',
        route: '/posts',
        icon: PostsWhiteIcon,
        selectedIcon: PostsIcon
    },
];

export const DIFFICULTY_LEVEL_OPTIONS = [
    {
        label: 'One',
        value: 1
    },
    {
        label: 'Two',
        value: 2
    },
    {
        label: 'Three',
        value: 3
    },
    {
        label: 'Four',
        value: 4
    },
    {
        label: 'Five',
        value: 5
    }
];

export const CATEGORY_DROPDOWN = [
    {
        label: "Vet",
        value: "VET"
    },
    {
        label: "Grooming",
        value: "GROOMING"
    },
    {
        label: "Training Center",
        value: "TRAINING_CENTER"
    }
];

export const VENDOR_STATUS_DROPDOWN = [
    {
        label: "Deleted",
        value: "DELETED"
    },
    {
        label: "Active",
        value: "ACTIVE"
    }
];

export const POST_STATUS_DROPDOWN = [
    {
        label: "Pending",
        value: "PENDING",
    },
    {
        label: "Rejected",
        value: "REJECTED"
    },
    {
        label: "Approved",
        value: "APPROVED"
    }
];