import { CaretDownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { RootState } from "redux/rootReducer";
import {
  deleteVendorEndpoint,
  listVendorsEndpoint,
  showVendorEndpoint,
} from "api/apiUrls";
import { listItemsAction } from "redux/actions/listItemsAction";
import { Modal, Select, Table } from "antd";
import VendorsTableModel from "model/tables/VendorsTableModel";
import {
  CREATE_VENDOR_ADMIN_PATH,
  CREATE_VENDOR_PATH,
  SHOW_VENDOR_PATH,
  UPDATE_VENDOR_PATH,
} from "Routes/paths";
import { deleteItemAction } from "redux/actions/deleteItemAction";
import { listSingleItemAction } from "redux/actions/listSingleItemAction";
import { SET_VENDOR_ID } from "redux/types";
import { VENDOR_STATUS_DROPDOWN } from "constants/index";

const VendorsIndex = () => {
  const navigate = useNavigate();

  const [deletingId, setDeletingId] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch: any = useDispatch();

  const { listItemsLoading, items, pagination } = useSelector(
    (state: RootState) => state.listItemsReducer
  );

  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteItemReducer
  );

  useEffect(() => {
    const page = searchParams.get("page") ?? 1;
    const deleted = searchParams.get("deleted") ?? false;
    dispatch(
      listItemsAction(listVendorsEndpoint, `deleted=${deleted}&page=${page}`)
    );
  }, [searchParams]);

  return (
    <section className="w-full h-full overflow-y-scroll">
      <div className="w-full px-10 pt-10 pb-32 flex flex-col items-end">
        <Modal
          title="Delete Confirmation"
          open={deleteModalOpen}
          onOk={async () => {
            await dispatch(deleteItemAction(deleteVendorEndpoint, deletingId));
            setDeleteModalOpen(false);
            const page = searchParams?.get("page") ?? 1;
            await dispatch(
              listItemsAction(listVendorsEndpoint, `page=${page}`)
            );
          }}
          onCancel={() => setDeleteModalOpen(false)}
          okButtonProps={{
            className: "bg-[red] hover:!bg-[red] hover:opacity-50",
          }}
          className="w-full"
          confirmLoading={deleteLoading}
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
        <div className="w-full flex justify-between px-4">
          <Select
            placeholder="Vendor Status"
            className="min-h-[48px] w-1/2"
            suffixIcon={<CaretDownOutlined className="text-xl" />}
            options={VENDOR_STATUS_DROPDOWN}
            onSelect={(value: any) => {
              if (value === "DELETED") {
                searchParams.set("deleted", "true");
                setSearchParams(searchParams);
              } else if (value === "ACTIVE") {
                searchParams.set("deleted", "false");
                setSearchParams(searchParams);
              }
              // formik.setFieldValue("difficulty_level", value);
            }}
          />
          <button
            onClick={() => navigate(CREATE_VENDOR_PATH)}
            className="bg-main rounded-md px-5 py-2 flex items-center justify-center text-white font-medium gap-x-2"
          >
            <PlusCircleOutlined className="font-bold" />
            Create New Item
          </button>
        </div>
        <Table
          className="m-4 shadow-md rounded-md w-[97%] border border-gray-300 overflow-x-auto"
          dataSource={items}
          loading={listItemsLoading}
          tableLayout="auto"
          columns={VendorsTableModel(
            async (id: string) => {
              await dispatch(
                listSingleItemAction(showVendorEndpoint, id, () => {
                  navigate(SHOW_VENDOR_PATH);
                })
              );
            },
            async (id: string) => {
              await dispatch(
                listSingleItemAction(showVendorEndpoint, id, () => {
                  navigate(UPDATE_VENDOR_PATH);
                })
              );
            },
            setDeleteModalOpen,
            setDeletingId,
            (value: any) => {
              dispatch({ type: SET_VENDOR_ID, payload: value });
              navigate(CREATE_VENDOR_ADMIN_PATH);
            }
          )}
          pagination={{
            current:
              Number(searchParams.get("page")) === 0
                ? 1
                : Number(searchParams.get("page")),
            pageSize: 8,
            total: Number(pagination?.last_page) * 8,
            showSizeChanger: false,
            onChange: (page: number) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            },
          }}
        />
      </div>
    </section>
  );
};

export default VendorsIndex;
