import api from "api";
import { loginEndpoint } from "api/apiUrls";

export const loginService = async (data: any) => {
    const response = await api.post(
        loginEndpoint,
        data
    );

    return response;
}