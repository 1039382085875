import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { RootState } from "redux/rootReducer";
import { listItemsAction } from "redux/actions/listItemsAction";
import { listPostsEndpoint, showPostEndpoint } from "api/apiUrls";
import { Modal, Select, Table } from "antd";
import PostsTableModel from "model/tables/PostsTableModel";
import { CaretDownOutlined } from "@ant-design/icons";
import { POST_STATUS_DROPDOWN } from "constants/index";
import { listSingleItemAction } from "redux/actions/listSingleItemAction";
import { SHOW_POST_PATH } from "Routes/paths";

const PostsIndex = () => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { listItemsLoading, items, pagination } = useSelector(
    (state: RootState) => state.listItemsReducer
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState("");

  const [photo, setPhoto] = useState("");
  const [photoModalOpen, setOpenPhotoModal] = useState(false);

  const [postPhotoModalOpen, setOpenPostPhotoModal] = useState(false);

  useEffect(() => {
    const page = searchParams.get("page") ?? 1;
    const status = searchParams.get("status") ?? "PENDING";
    dispatch(
      listItemsAction(listPostsEndpoint, `status=${status}&page=${page}`)
    );
  }, [searchParams]);

  return (
    <section className="w-full h-full overflow-y-scroll">
      <Modal
        open={photoModalOpen}
        onCancel={() => setOpenPhotoModal(false)}
        title="Profile Image"
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        className="w-full flex flex-col items-start gap-y-4"
      >
        <img src={photo} alt="" />
      </Modal>
      <Modal
        open={postPhotoModalOpen}
        onCancel={() => setOpenPostPhotoModal(false)}
        title="Post Picture"
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        className="w-full flex flex-col items-start gap-y-4"
      >
        <img src={photo} alt="" />
      </Modal>
      <div className="w-full px-10 pt-10 pb-32 flex flex-col items-end">
        <div className="w-full flex justify-between px-4">
          <Select
            placeholder="Post Status"
            className="min-h-[48px] w-1/2"
            suffixIcon={<CaretDownOutlined className="text-xl" />}
            options={POST_STATUS_DROPDOWN}
            onSelect={(value: any) => {
              searchParams.set("status", value);
              setSearchParams(searchParams);
              // formik.setFieldValue("difficulty_level", value);
            }}
          />
        </div>
        <Table
          className="m-4 shadow-md rounded-md w-[97%] border border-gray-300 overflow-x-auto"
          dataSource={items}
          loading={listItemsLoading}
          tableLayout="auto"
          columns={PostsTableModel(
            async (id: string) => {
                await dispatch(
                  listSingleItemAction(showPostEndpoint, id, () => {
                    navigate(SHOW_POST_PATH);
                  })
                );
            },
            (img: string) => {
              setPhoto(img);
              setOpenPhotoModal(true);
            },
            (img: string) => {
              setPhoto(img);
              setOpenPostPhotoModal(true);
            },
            setDeleteModalOpen,
            setDeletingId
          )}
          pagination={{
            current:
              Number(searchParams.get("page")) === 0
                ? 1
                : Number(searchParams.get("page")),
            pageSize: 8,
            total: Number(pagination?.last_page) * 8,
            showSizeChanger: false,
            onChange: (page: number) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            },
          }}
        />
      </div>
    </section>
  );
};

export default PostsIndex;
