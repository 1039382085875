import
{
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAILURE
}
from "redux/types";

const initialState = {
    loginLoading: false,
    token: null,
    errors: null
}

export const loginReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case LOGIN_LOADING :
            return {...state, loginLoading: action.payload};
        case LOGIN_SUCCESS :
            return {...state, token: action.payload.data.token};
        case LOGIN_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}