import
{
    DELETE_ITEM_LOADING,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { deleteItemService } from "services/deleteItemService";

export const deleteItemAction = (url: string, id: string, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: DELETE_ITEM_LOADING, payload: true});
        const response = await deleteItemService(url, id);
        dispatch({type: DELETE_ITEM_SUCCESS, payload: response.data});
        callback && callback();
    }
    catch (err) {
        dispatch({type: DELETE_ITEM_FAILURE, payload: err});
    }
    finally {
        dispatch({type: DELETE_ITEM_LOADING, payload: false});
    }
}