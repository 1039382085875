import
{
    UPDATE_ITEM_LOADING,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    data: null,
    errors: null
}

export const updateItemReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_ITEM_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_ITEM_SUCCESS :
            return {...state, data: action.payload.data};
        case UPDATE_ITEM_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}