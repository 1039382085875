import AppRoutes from "./Routes/AppRoutes";

import "./App.css";

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
